#validatorApp

  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
  }

  .metadata-tags-container{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    background-color: rgba(0,0,0,0.6);
  }

  .metadata-tags-container:hover{
    opacity: 1;
  }

  .badge-visit {
    color: #fff;
    background-color: #563d7c;  
  }

  .text-visit {
    color: #563d7c;
  }