:export {
explorer: #2176ff;
explorerShade1: #6E18EA;
explorerShade2: #C7A0F9;
explorerShade3: #AD6BF2;
place: #8dc500;
placeShade1: #6B8C03;
placeShade2: #DBF473;
placeShade3: #B4F9C4;
observation: #51ce7a;
observationShade1:#00992B;
observationShade2:#B4F9C4;
observationShade3:#86EAA2;
lead: #9336fd;
leadShade1:#6E18EA;
leadShade2:#C7A0F9;
leadShade3:#AD6BF2;
project: #ff8800;
projectShade1:#C1660C;
projectShade2:#FCC99A;
projectShade3:#ED9D56;
task: #ff7370;
taskShade1:#DD3636;
taskShade2:#EFB4B4;
taskShade3:#F49595;
admin: #f050ae;
adminShade1:#B70F77;
adminShade2:#FCBBE6;
adminShade3:#F98ACF;
sample: #00F7E5;
sampleShade1:#05A595;
sampleShade2:#AEFFF5;
sampleShade3:#89F4E7;
delivery: #FF00FF;
deliveryShade1:#BC08C1;
deliveryShade2:#F7A4FC;
deliveryShade3:#F56EF9;
}
