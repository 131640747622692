$explorer: #2176ff;
$explorerShade1: #6e18ea;
$explorerShade2: #c7a0f9;
$explorerShade3: #ad6bf2;
$place: #8dc500;
$placeShade1: #6b8c03;
$placeShade2: #dbf473;
$placeShade3: #b4f9c4;
$observation: #51ce7a;
$observationShade1: #00992b;
$observationShade2: #b4f9c4;
$observationShade3: #86eaa2;
$lead: #9336fd;
$leadShade1: #6e18ea;
$leadShade2: #c7a0f9;
$leadShade3: #ad6bf2;
$project: #ff8800;
$projectShade1: #c1660c;
$projectShade2: #fcc99a;
$projectShade3: #ed9d56;
$task: #ff7370;
$taskShade1: #dd3636;
$taskShade2: #efb4b4;
$taskShade3: #f49595;
$admin: #f050ae;
$adminShade1: #b70f77;
$adminShade2: #fcbbe6;
$adminShade3: #f98acf;
$sample: #00f7e5;
$sampleShade1: #05a595;
$sampleShade2: #aefff5;
$sampleShade3: #89f4e7;
$delivery: #ff00ff;
$deliveryShade1: #bc08c1;
$deliveryShade2: #f7a4fc;
$deliveryShade3: #f56ef9;
$brand: #444488;

$colors: (
  'explorers': $explorer,
  'places': $place,
  'observations': $observation,
  'leads': $lead,
  'projects': $project,
  'tasks': $task,
  'admins': $admin,
  'samples': $sample,
  'deliveries': $delivery,
  'brands': $brand,
);

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 100%;
  padding: 6px 16px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

@each $colorname, $color in $colors {
  .badge-#{$colorname} {
    color: #fff;
    background-color: $color;
  }

  .text-#{$colorname} {
    color: $color;
  }
}

.table {
  table-layout: fixed;
}

.text-lead {
  @extend .text-info;
}

.metadata-tags-container {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: rgba(0, 0, 0, 0.6);
}

.metadata-tags-container:hover {
  opacity: 1;
}

.google-map-edit {
  position: relative;
}

.google-map-edit:after {
  width: 22px;
  height: 32px;
  display: block;
  content: ' ';
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -32px 0 0 -11px;
  background: url('https://maps.gstatic.com/mapfiles/markers2/arrow.png');
  pointer-events: none; /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}

.google-match-card {
  &.hovered {
    @extend .border-primary;
    transform: scale(1.05);
    transform-origin: left center;
  }

  &.active {
    @extend .bg-primary;
    @extend .text-white;
    a {
      @extend .text-white;
    }

    &.hovered {
      @extend .border-light;
    }
  }
  transform: scale(1);
  transform-origin: left center;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

@for $i from 0 through 50 {
  .scale-#{$i} {
    color: scale-color(mix($yellow, $red, $i * 2), $saturation: -20%);
  }

  .scale-bg-#{$i} {
    background-color: scale-color(
      mix($yellow, $red, $i * 2),
      $saturation: -20%
    );
  }

  .scale-border-#{$i} {
    border-color: scale-color(
      mix($yellow, $red, $i * 2),
      $saturation: -20%
    ) !important;
  }
}

@for $i from 51 through 100 {
  .scale-#{$i} {
    color: scale-color(mix($green, $yellow, ($i - 51) * 2), $saturation: -20%);
  }

  .scale-bg-#{$i} {
    background-color: scale-color(
      mix($green, $yellow, ($i - 51) * 2),
      $saturation: -20%
    );
  }

  .scale-border-#{$i} {
    border: 1px
      solid
      scale-color(
        mix($green, $yellow, ($i - 51) * 2),
        $saturation: -20%
      ) !important;
  }
}
