

#explorerApp {
  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
  }

  .font-mono {
    font-family: 'Roboto Mono', monospace;
  }

  input[type="text"],
  input[type="email"],
  input[type="date"] {
    height: calc(2.25rem + 2px);
  }

  nav.navbar{
    z-index: 1000;
  }

  #notifications {
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  }

  #notifications:after {
     content: "";
     background-color: inherit;
     bottom: -200px;
     height: 200px;
     width: 100%;
     left: 0;
     right: 0;
     position: absolute;
  }

  #notifications.show {
    transform: translateY(0%);
    opacity: 1;
    transition: transform 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .progress .progress-bar {
    transition: width 0.25s;
  }

  .server-errors{
    padding-left: 20px;
  }

  @keyframes pulsebeat {
    0%{ transform: scale(1); }
    50%{ transform: scale(0.90); }
    100%{ transform: scale(1); }
  }

  .pulsebeat{
    animation: pulsebeat 2s infinite;
  }

  @keyframes validations-processing {
    0%{ border-color: #ffc107; }
    100%{ border-color: #ffe083; }
  }

  .validations-processing{
    animation: validations-processing 1s infinite;
    animation-direction: alternate;
  }

  .animate-opacity{
    transition: opacity 0.25s;
  }

  .card,.card *{
    transition: color 0.25s;
    transition: border-color 0.25s;
  }

  .card-body>.form-group:nth-last-child(1),.card-body>.card:nth-last-child(1) {
    margin-bottom: 0 !important;
  }

  .j-form-view {
    min-height: calc(100vh - 89px - 1rem)
  }


}
