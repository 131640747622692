
.navbar {
  padding: 0.5rem 1rem;
}


.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, 
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: $green;
}


.btn {
  text-transform: none;
  cursor: pointer;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, 
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background: $blue;
}

.intercom-launcher-frame {
  display: none !important;
}

.leaflet-control-attribution{
  display: none !important;
}

.btn-xs{
  height: 21px;
  line-height: 8px;
}

.btn-vertical {
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
    transform: rotate(90deg);
}

.border-transparent {
  border-color: rgba(0,0,0,0) !important
}