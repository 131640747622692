
@import "~bootswatch/dist/cyborg/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/cyborg/bootswatch";

@import "~react-confidence-scale/dist/index.css";

@import "./css/explorer_app.scss";
@import "./css/admin_app.scss";
@import "./css/validator_app.scss";

@import "./css/overrides.scss";
